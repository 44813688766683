import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {ecomAppDefID} from '../constants';
import {EditorSDK, PageRef} from '@wix/platform-editor-sdk';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';
import {EcomPublicApi} from '../editorScript';

export async function missingPagesMigration(sdk: EditorSDK, EPApi: EcomPublicApi, biLogger) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  const storesPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  const existsMandatoryPages = await Promise.all(
    storesPages.map((page) => {
      const pageRef: PageRef = {id: page.id, type: 'DESKTOP'};
      return sdk.pages.data.get('', {
        pageRef,
      });
    })
  ).then((pages: any) => {
    return pages.map((pageData) => pageData.tpaPageId);
  });

  const promises = [PageMap.CART, PageMap.PRODUCT, PageMap.THANKYOU, PageMap.CHECKOUT].map((mandatoryPage) => {
    if (!existsMandatoryPages.includes(mandatoryPage)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      biLogger.exposureEventForTests({testName: 'missingPagesMigration', is_eligible: true, type: mandatoryPage});
      return EPApi.addPage(mandatoryPage, STORES_APP_DEF_ID);
    } else {
      return Promise.resolve();
    }
  });

  await Promise.all<any>(promises);
}
