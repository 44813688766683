import {
  APP_INSTALLED_ACTION,
  APP_REMOVED_ACTION,
  BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  ECOM_APP_DEF_ID,
  STORES_APP_DEF_ID,
} from './constants';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomComponent, withEcomPlatform} from '@wix/ecom-platform-sdk/dist/es/src';
import {doTransaction} from '../editor-script/transaction';
import {ecomAppDefID, Events, SPECS} from '../editor-script/constants';
import {ExperimentsApi} from '../common/experiments/ExperimentsApi';
import {delay} from '@wix/wixstores-client-core/dist/src/utils/delay';
import {MEMBERS_AREA_APP_DEF_ID} from '@wix/members-area-integration-kit/dist/src/constants';
import {isMembersAreaInstalled, withMembersArea} from '@wix/members-area-integration-kit';
import {translateFunctionFactory} from '../common/translations/translations';
import {getAppManifest, handleAppsManagerActions} from '../common/appManifest/appManifest';
import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {EditorSDK, ContextParams, IGetDataByAppDef} from '@wix/platform-editor-sdk';
import {
  setSentryInstance,
  errorReporter,
  withErrorReportingWrapping,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {createSentryInstance} from '../common/monitoring';
import {EcomPublicApi} from '../editor-script/editorScript';
import {missingPagesMigration} from '../editor-script/migration-scripts/missingPagesMigration';
import {createStoreFrontBILogger} from '@wix/wixstores-client-core/dist/es/src/bi/configure-front-bi-logger';

const PROGRESS_BAR_MIN_STEP_DELAY = 1000;
const ecomComponents = [
  EcomComponent.CART_ICON,
  EcomComponent.CART,
  EcomComponent.CHECKOUT,
  EcomComponent.THANK_YOU_PAGE,
];
let sdk: EditorSDK;
let t: (key: string) => string;
let installationStep = 0;
let actualProgressBarStep = 0;
let experimentsApi: ExperimentsApi;
let locale: string = 'en';
let options: ContextParams;

async function getEcomPagesData() {
  const ecomApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  return allSitePages.filter((page) => page.tpaApplicationId === ecomApplicationId);
}

async function checkIfPageExists(page: PageMap) {
  const ecomPagesData = await getEcomPagesData();
  return !!ecomPagesData.find((p) => p.tpaPageId === page);
}

function checkIfBlocksProductPageExists(): Promise<boolean> {
  return sdk.application.isApplicationInstalled('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
}

function installBlocksProductPage() {
  return sdk.document.tpa.add.application('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
}

function addStoresPages() {
  return doTransaction(sdk, async () => {
    const ecomPublicApi = (await sdk.application.getPublicAPI('', {
      appDefinitionId: ECOM_APP_DEF_ID,
    })) as EcomPublicApi;
    if (
      (experimentsApi.enabled(SPECS.InstallBlocksProductPage) && (await checkIfBlocksProductPageExists())) ||
      (!experimentsApi.enabled(SPECS.InstallBlocksProductPage) && (await checkIfPageExists(PageMap.PRODUCT)))
    ) {
      installationStep++;
      return Promise.resolve();
    }

    await provisionStore(ecomPublicApi.getInstance());
    const productPagePromise = experimentsApi.enabled(SPECS.InstallBlocksProductPage)
      ? installBlocksProductPage()
      : ecomPublicApi.addPage(PageMap.PRODUCT, STORES_APP_DEF_ID);
    const shopPagePromise = ecomPublicApi.addPage(PageMap.GALLERY, STORES_APP_DEF_ID, true);

    await Promise.all<any>([shopPagePromise, productPagePromise]);
    await ecomPublicApi.setStateForStoresPages();
    installationStep++;
  });
}

async function startInstallationProgressBar() {
  const progressBarStepTitles = [
    '',
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textOne'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textTwo'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textThree'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textFour'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textFive'),
  ];
  await sdk.editor.openProgressBar('', {
    title: t('memberPages.addModal.memberPagesTab.installation.popup.title'),
    totalSteps: 5,
    currentStep: 0,
    stepTitle: progressBarStepTitles[0],
  });

  while (actualProgressBarStep < 5) {
    if (installationStep > actualProgressBarStep) {
      actualProgressBarStep++;
    }
    await delay(PROGRESS_BAR_MIN_STEP_DELAY);
    await sdk.editor.updateProgressBar('', {
      currentStep: actualProgressBarStep,
      stepTitle: progressBarStepTitles[actualProgressBarStep],
    });
  }

  await sdk.editor.updateProgressBar('', {
    currentStep: actualProgressBarStep,
    stepTitle: progressBarStepTitles[actualProgressBarStep],
  });
  await delay(PROGRESS_BAR_MIN_STEP_DELAY);
  await sdk.editor.closeProgressBar('', {});
}

async function migrateEcomPagesToStores() {
  const allSitePages = await sdk.pages.data.getAll('');
  const ecomPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID);
  if (ecomPages.some((page) => page.tpaPageId === PageMap.PRODUCT)) {
    return ecomPages.map((page) => {
      if (page.tpaPageId === PageMap.CHECKOUT) {
        return Promise.resolve();
      }
      return sdk.pages.data.update('', {
        pageRef: {id: page.id, type: 'DESKTOP'},
        data: {managingAppDefId: STORES_APP_DEF_ID},
      });
    });
  }
}

async function editorReady(editorSDK: EditorSDK, _appToken: string, _options: ContextParams) {
  try {
    options = _options;
    sdk = editorSDK;

    setSentryInstance(createSentryInstance(options.monitoring));

    locale = (await sdk.editor.environment.getLocale()) || locale;
    t = await translateFunctionFactory(locale);
    experimentsApi = new ExperimentsApi(options.initialAppData.instance);
    await experimentsApi.fetch(false);

    if (experimentsApi.enabled(SPECS.EcomPlatformInstallation) && options.firstInstall) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      startInstallationProgressBar();
    }

    const isEcomPlatformInstalled = await sdk.application.isApplicationInstalled('', {
      appDefinitionId: ECOM_APP_DEF_ID,
    });
    if (isEcomPlatformInstalled) {
      installationStep++;
      options.firstInstall && (await addStoresPages());
    }

    const membersAreaInstalled = await isMembersAreaInstalled();
    if (membersAreaInstalled) {
      installationStep++;
    }
    installationStep++;

    const biLogger = createStoreFrontBILogger({uuid: options.initialAppData.userId}, null, {
      storeId: options.initialAppData.instanceId,
      isMerchant: true,
      appName: 'wixstores worker',
    });

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    await sdk.addEventListener('siteWasSaved', async () => {
      const appData = (await sdk.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID)) as IGetDataByAppDef & {
        permissions: {revoked: boolean};
      };
      if (appData?.permissions.revoked) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.exposureEventForTests({testName: 'siteWasSaved', is_eligible: true});
        return sdk.application.uninstall('', {openConfirmation: false});
      }
    });

    const ecomPublicApi = (await sdk.application.getPublicAPI('', {appDefinitionId: ECOM_APP_DEF_ID})) as EcomPublicApi;
    if (experimentsApi.enabled('specs.stores.MissingPagesMigration') && !options.firstInstall && ecomPublicApi) {
      await missingPagesMigration(sdk, ecomPublicApi, biLogger);
    }

    await migrateEcomPagesToStores();

    await ecomPublicApi?.setStateForStoresPages();
  } catch (e) {
    errorReporter(e);
  }
}

async function handleAction({type, payload}) {
  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === STORES_APP_DEF_ID) {
    installationStep++;
  }

  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === ECOM_APP_DEF_ID) {
    installationStep++;
    await addStoresPages();
  }

  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === MEMBERS_AREA_APP_DEF_ID) {
    installationStep++;
  }

  if (
    type === APP_REMOVED_ACTION &&
    payload?.appDefinitionId === ECOM_APP_DEF_ID &&
    experimentsApi.enabled(SPECS.DeleteNewStoresUponEcomDeletion)
  ) {
    await sdk.application.uninstall('', {openConfirmation: false});
  }
}

async function provisionStore(instance) {
  return fetch('/_api/wix-ecommerce-renderer-web/store-manager/provision-store', {
    method: 'post',
    headers: {
      Authorization: instance,
    },
  });
}

const editorScript = {
  editorReady,
  handleAction,
  onEvent: async ({eventType, eventPayload}: {eventType: Events; eventPayload: any}) => {
    if (eventType === Events.appActionClicked) {
      return handleAppsManagerActions(sdk, eventPayload.actionId);
    } else if (eventType === Events.deletePage) {
      const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
      const allSitePages = await sdk.pages.data.getAll('');
      const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
      const numOfShopPages = ecomPages.filter((page) => page.tpaPageId?.startsWith(PageMap.GALLERY)).length;
      if (numOfShopPages > 1) {
        const {pageRef} = eventPayload;
        return doTransaction(sdk, () => sdk.pages.remove('', {pageRef}));
      } else {
        return doTransaction(sdk, () => sdk.application.uninstall('', {openConfirmation: true}));
      }
    } else if (eventType === Events.addShopPage) {
      await sdk.editor.openProgressBar('', {
        title: t('Installation_Popup_Loader_shop_page_Header'),
        totalSteps: 2,
        currentStep: 1,
        stepTitle: t('Installation_Popup_Loader_shop_page_Text1'),
      });
      const ecomPublicApi = (await sdk.application.getPublicAPI('', {
        appDefinitionId: ECOM_APP_DEF_ID,
      })) as EcomPublicApi;
      const addPromise = ecomPublicApi.addPage(PageMap.GALLERY, STORES_APP_DEF_ID, true);
      await delay(PROGRESS_BAR_MIN_STEP_DELAY);
      await sdk.editor.updateProgressBar('', {
        currentStep: 2,
        stepTitle: t('Installation_Popup_Loader_shop_page_Text2'),
      });
      await addPromise;
      await sdk.editor.closeProgressBar('', {});
    }
  },
  getAppManifest: ({appManifestBuilder}: {appManifestBuilder: AppManifestBuilder}, editorSDK: EditorSDK) => {
    return getAppManifest({
      t,
      locale,
      token: '',
      hasStoresPremium: experimentsApi.hasStoresPremium,
      exps: experimentsApi.experiments,
      appManifestBuilder,
      editorSDK,
      options,
      pagesPanelTitle: t('Pages_Panel_storesPages'),
    });
  },
};

export const storesEditorScript = withErrorReportingWrapping(
  withMembersArea(withEcomPlatform(editorScript as any, ecomComponents), {
    installAutomatically: false,
  })
);
